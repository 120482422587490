.qr-wrapper {
    width: 100vw;
    height: 100vh;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2vh;
    box-sizing: border-box;
}

#qr-img {
    margin: 0;
    padding: 0;
    width: 250px;
    height: 250px;
}

@media screen and (min-width: 1200px) {
    #qr-img {
        width: 600px;
        height: 600px;
    }
}